/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { HantStandaloneVideoSlider as ResolvedStandaloneVideoSlider } from 'sites/hant/components/standalone/VideoSlider';

export const VideoSlider: typeof ResolvedStandaloneVideoSlider = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneVideoSlider {...props} />
        </ErrorBoundary>
    );
});

export type VideoSliderProps = PropsFromComponent<typeof VideoSlider>;

/** @deprecated Use slot architecture instead */
export const VideoSliderWith = (extras: DynamicStandaloneExtras): typeof VideoSlider => {
    return function VideoSlider(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'VideoSlider');
        return <ResolvedStandaloneVideoSlider {...mergeProps({ options: { theme } }, props)} />;
    }
}