import { useStableProps } from '@/hooks/useStable';
import StandaloneVideoSlider from 'base/components/VideoSlider';

export const HantStandaloneVideoSlider: typeof StandaloneVideoSlider = (props) => {
  const stableProps = useStableProps(
    {
      options: {
        $swiper: { $arrow: { className: 'text-gray-300' } },
        $header: { $arrow: { className: '-mt-0.5' } },
      },
    },
    props,
  );

  return <StandaloneVideoSlider {...stableProps} />;
};

export default HantStandaloneVideoSlider;
